import React from 'react'
import { locale } from '../../../src/common/i18n'

interface Props {
  styles: { [k: string]: string }
}

const CondicionesCa = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Condicions generals de contractació</h1>
    <p>
      El present acord regula les condicions generals de compra dels diversos
      productes i serveis oferts en la present pàgina web per RODI METRO, S.L.
      (D'ara en endavant RODI) amb NIF B60864311 i domicili al C / Pere de
      Cabrera, 16, 5a planta de (25001) Lleida (ESPANYA).
    </p>
    <p>
      El CLIENT en el moment que completa el procés de registre, i compra algun
      dels nostres productes, accepta i se sotmet expressament a les clàusules
      especificades a continuació, així com a les condicions d'accés i ús del
      nostre portal i la seva política de privacitat, accessibles a través de
      l'enllaç "Avisos Legals".
    </p>
    <p>
      El CLIENT reconeix disposar de capacitat legal suficient per obligar-se
      contractualment.
    </p>
    <p>
      El present contracte està permanentment accessible en els avisos legals de
      la nostra pàgina web, i es pot descarregar en el moment de la
      contractació.
    </p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>1. Preus i impostos</strong>
    </h2>
    <p>
      En contraprestació pel producte adquirit, el CLIENT accepta pagar
      expressament a RODI les quantitats especificades en el moment de la compra
      a la relació de preus establertes en la web{' '}
      <u>
        <a href="https://www.rodi.cat/">www.rodi.cat</a>
      </u>{' '}
      per als productes escollits.
    </p>
    <p>
      Els productes que s'ofereixen en aquesta web estan subjectes a l'Impost
      del Valor Afegit (IVA) que ESTÀ INCLÒS en els preus que es detallen per a
      cada producte.
    </p>
    <p>
      El cost de muntatge o instal·lació dels productes NO ESTÀ INCLÒS en el
      preu.
    </p>
    <p>
      Un cop finalitzada la selecció de productes i de manera prèvia a la
      confirmació de la comanda i abans de procedir al seu pagament, a la
      pantalla apareixerà el preu final de la compra que inclourà l'IVA (Impost
      sobre el Valor Afegit), les despeses d'instal·lació i de muntatge, que es
      reflectiran en l'ordre de comanda en el moment d'efectuar la compra i en
      la factura.
    </p>
    <p>
      No s'inclou tot allò que no aparegui especificat en la descripció com
      inclòs.
    </p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>2. Procediment de compra</strong>
    </h2>
    <p>
      A través d'aquesta pàgina web només podran ser realitzats comandes per
      persones físiques majors de 14 anys i societats.
    </p>
    <p>
      A la pàgina web es detallen tots els nostres productes de manera
      individualitzada. El procediment per efectuar i gestionar la compra es va
      indicant a la pàgina web i només s'han de seguir els següents passos:
    </p>
    <ol className={styles.listNumber}>
      <li>
        <p>Escollir el producte que desitgi adquirir.</p>
      </li>
      <li>
        <p>
          En seleccionar un producte podrà ser afegit a la cistella de la Compra
          i podrà escollir entre seguir comprant o tramitar la comanda.
        </p>
      </li>
      <li>
        <p>
          Per tramitar la comanda, si és el primer cop que accedeix al nostre
          portal, cal omplir un formulari de registre amb les seves dades
          personals i crear un compte personal com a usuari. Completat aquest
          pas rebrà, al correu electrònic que hagi indicat, la confirmació que
          el registre s'ha realitzat correctament. En cas que sigui un usuari ja
          registrat, per continuar amb el procés de compra, serà suficient que
          introdueixi el nom d'usuari i contrasenya que va generar en la primera
          compra. El codi d'usuari i la contrasenya són personals i corresponen
          a la persona que va efectuar el procés de registre, qui és responsable
          exclusiu seva custòdia. A través del seu compte podrà gestionar la
          informació que disposem i modificar o actualitzar les seves dades.
        </p>
      </li>
      <li>
        <p>
          A continuació, es procedirà a la verificació de la comanda on es
          detallen un a un els articles seleccionats i es calcula el preu total,
          incloent despeses de taller i impostos.
        </p>
      </li>
      <li>
        <p>
          Així mateix, caldrà seleccionar el taller on desitja que se li
          instal·li el producte i seleccionar una data a manera de cita per al
          muntatge.
        </p>
      </li>
      <li>
        <p>
          Un cop tramitat el procés, caldrà marcar de manera prèvia una casella
          de verificació de lectura i acceptació dels termes i condicions de
          venda.
        </p>
      </li>
      <li>
        <p>
          A la pàgina web se li facilitaran els mecanismes perquè pugui efectuar
          el pagament en funció del sistema triat en cada cas.
        </p>
      </li>
      <li>
        <p>
          Un cop introduïdes les dades per al pagament, haurà de confirmar la
          comanda prement el botó "Comprar".
        </p>
      </li>
      <li>
        <p>
          Un cop premi el botó "Comprar" rebrà instruccions per al lliurament
          del producte.
        </p>
      </li>
    </ol>
    <p>
      El CLIENT es dóna per informat que les fotografies i descripcions dels
      productes a la pàgina web són una imatge aproximada dels productes i
      serveis oferts per la qual cosa és possible que puguin diferir de
      l'original. Les descripcions i il·lustracions dels productes oferts a
      través de la present pàgina web es realitzen merament a títol informatiu.
    </p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>3. Disponibilitat i Lliurament</strong>
    </h2>
    <p>
      Els productes oferts per RODI es troben disponibles per la seva compra en
      tota la Unió Europea però el lliurament i el muntatge es realitzarà
      exclusivament en un dels tallers disponibles a la web.
    </p>
    <p>
      Les comandes es processaran una vegada que s'hagi rebut correctament el
      pagament.
    </p>
    <p>
      Els productes oferts per RODI estaran sempre subjectes a disponibilitat
      que s'indicarà específicament en la nostra web per a cada producte pel
      que, en cas de no trobar-se disponible algun article ofert, s'informarà
      degudament de la manca de disponibilitat al client. En condicions normals,
      tots els productes que apareixen a la nostra web es troben disponibles per
      al seu muntatge.
    </p>
    <p>
      En els casos en què, un cop realitzat la comanda, el producte contractat
      pel CLIENT es trobi esgotat, se li informarà degudament i se li oferirà un
      producte alternatiu de característiques similars de la mateixa preu i
      qualitat al comprat. Si, tot i així, el CLIENT no estigués interessat en
      acceptar l'alternativa proposada, RODI procedirà a la devolució de la suma
      ja abonada i l'informarà dels tràmits i terminis de reemborsament.
    </p>
    <p>
      El CLIENT haurà de seleccionar una de les dates disponibles al taller de
      la seva elecció que, un cop marcada, serà la data de lliurament del
      producte.
    </p>
    <p>
      Els lliuraments i instal·lacions es realitzaran en horari d'obertura
      comercial dels nostres tallers.
    </p>
    <p>
      Amb l'objecte de que en tot moment estigui informat de la gestió de la
      seva compra, rebrà per correu electrònic un avís de la confirmació de la
      seva comanda i del pagament.
    </p>
    <p>
      Així mateix, el CLIENT pot consultar l'estat de la seva comanda trucant en
      horari comercial d'obertura al telèfon 900 921 790 o enviant un correu
      electrònic a l'adreça{' '}
      <u>
        <a href="mailto:atencionalcliente@rodi.es">atencionalcliente@rodi.es</a>
      </u>
      .
    </p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>4. Pagament i períodes de pagament</strong>
    </h2>
    <p>S'accepta les següents formes de pagament:</p>
    <ul>
      <li>
        <p>
          Targeta de Crèdit: La transacció es realitza connectant directament
          amb l'entitat bancària a través de la seva passarel·la de pagament o,
          directament, al taller.
        </p>
      </li>
    </ul>
    <p>
      En els pagaments a través del web, un cop realitzat el pagament, rebrà un
      correu electrònic de confirmació.
    </p>
    <p>
      RODI no té coneixement de les dades referents a la targeta de crèdit. Tots
      els nostres sistemes de pagament són totalment segurs.
    </p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>5. Factures</strong>
    </h2>
    <p>
      Un cop efectuat i completat el servei en el taller, rebrà la Factura de la
      compra. Aquesta serà en format PDF en el seu espai personal dintre de la
      web o en paper si la sol·licita al taller.
    </p>
    <p>
      El CLIENT és responsable de la confirmació de la recepció de les
      notificacions i de posar en el nostre coneixement qualsevol modificació
      sobre les seves dades, quedant RODI exonerada de qualsevol responsabilitat
      derivada per aquesta circumstància.
    </p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>6. Garantia</strong>
    </h2>
    <p>
      Tots els nostres productes queden sotmesos a controls de qualitat i estan
      garantits contra defectes de producció per un termini de 2 anys des de la
      data de compra. La garantia cobreix qualsevol vici de fabricació, de
      disseny o material, tot i que caldrà que es posi de manifest la falta de
      conformitat en un termini de 14 dies des que va tenir coneixement d'ella.
      La garantia cobreix únicament els productes defectuosos. No cobreix un ús
      inadequat, o altres que no siguin atribuïbles a un defecte del proveïdor o
      a una tara en el producte. Durant el període de garantia el CLIENT podrà
      acudir al taller on li van instal·lar el producte i procedirem a la seva
      reparació o substitució.
    </p>
    <p>El document de garantia és la factura de compra.</p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>7. Devolucions</strong>
    </h2>
    <p>
      Només s'admetran devolucions o canvis dels productes en cas de defecte de
      fàbrica o errors de muntatge. En qualsevol cas, no han d'haver
      transcorregut més de 14 dies des de la recepció del producte. Es
      comprovarà prèviament a l'acceptació que es tracta d'un defecte o anomalia
      de fabricació o d'embalatge i no un ús, inadequat o negligent.
      S'acompanyaran sempre de la corresponent la factura.
    </p>
    <p>
      Tant en el cas de devolució per defecte de fabricació com en el cas
      d'error en la comanda s'oferirà CLIENT, si és possible, la reparació de la
      peça o la seva substitució per una altra alternativa sense cost addicional
      i sense dret, per part del CLIENT , a cap tipus de compensació.
    </p>
    <p>
      En tot cas, la devolució quedarà sempre subjecta a previ examen per part
      RODI de l'estat del producte objecte de devolució i a la verificació que
      s'han complert els requisits exigibles en les presents condicions de
      venda. Un cop rebuda la devolució i en cas que sigui procedent,
      reemborsarem l'import cobrat en un termini màxim de 30 dies i en els
      mateixos termes que es van usar per realitzar el pagament.
    </p>
    <p>
      RODI es reserva el dret de rebutjar devolucions comunicades fora del
      termini fixat o de productes que no compleixin els criteris per a la seva
      devolució.
    </p>
    <p>
      Les devolucions es gestionaran a través de l'enviament d'un correu
      electrònic a{' '}
      <u>
        <a href="mailto:atencionalcliente@rodi.es">atencionalcliente@rodi.es</a>
      </u>{' '}
      o acudint al taller realitzant una descripció dels motius i de les causes
      al·legades per a la devolució. Així mateix, caldrà assenyalar el nom i
      cognoms i el número de referència de la comanda. Un cop rebuda la petició,
      la tramitarem oportunament.
    </p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>8. Dret de Desistiment</strong>
    </h2>
    <p>
      Té vostè dret a desistir del present contracte en un termini de 14 dies
      naturals sense necessitat de justificació.
    </p>
    <p>
      El termini de desistiment expirarà, en qualsevol cas, en el moment de la
      instal·lació del producte al taller.
    </p>
    <p>
      Per exercir el dret de desistiment, haurà vostè notificar a RODI METRO,
      S.L. C / Pere de Cabrera, 16, 5a planta, 25001 Lleida, correu electrònic:{' '}
      <u>
        <a href="mailto:atencionalcliente@rodi.es">atencionalcliente@rodi.es</a>
      </u>
      , la seva decisió de desistir del contracte a través d'una declaració
      inequívoca (per exemple, una carta enviada per correu postal, fax o correu
      electrònic ). Podrà utilitzar el model de formulari de desistiment que
      figura a continuació, encara que el seu ús no és obligatori.
    </p>
    <p>Model de formulari de desistiment</p>
    <p>A l'atenció de</p>
    <p>RODI METRO, S.L.</p>
    <p>C / Pere de Cabrera, 16, 5a planta</p>
    <p>25001 Lleida</p>
    <p>
      <u>
        <a href="mailto:atencionalcliente@rodi.es">atencionalcliente@rodi.es</a>
      </u>
    </p>
    <p>
      Per la present li comunico que desisteixo del meu contracte de venda del
      següent bé:
    </p>
    <p>Rebut / Comanda el:</p>
    <p>Nom del consumidor:</p>
    <p>Signatura del consumidor</p>
    <p>Data</p>
    <p>
      Per complir el termini de desistiment, només cal que la comunicació
      relativa a l'exercici de la seva banda d'aquest dret sigui tramesa abans
      que venci el termini corresponent.
    </p>
    <p>Conseqüències del desistiment:</p>
    <p>
      En cas de desistiment per part seva, li retornarem tots els pagaments
      rebuts, sense cap demora indeguda i, en tot cas, com a molt tard 14 dies
      naturals a partir de la data en què se'ns informe de la seva decisió de
      desistir del present contracte. Procedirem a efectuar el reemborsament
      utilitzant el mateix mitjà de pagament emprat per vostè per a la
      transacció inicial, llevat que hi hagi disposat expressament el contrari;
      en tot cas, no incorrerà en cap despesa com a conseqüència del
      reemborsament.
    </p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>9. Perfecció del contracte i modificacions dels serveis</strong>
    </h2>
    <p>
      El present contracte es perfeccionarà amb la instal·lació i pagament del
      producte.
    </p>
    <p>
      RODI es reserva el dret de modificar, de forma les característiques i
      condicions dels seus serveis, sempre amb l'objectiu de millorar-los i que
      suposi un benefici per al CLIENT.
    </p>
    <h2 className={styles.section_title}>
      <strong>10. Obligacions de les parts</strong>
    </h2>
    <p>
      RODI es compromet a proporcionar al CLIENT els productes i serveis que
      hagin estat contractats aplicant la màxima diligència en la prestació dels
      mateixos, així com a tenir cura del manteniment de les instal·lacions
      necessàries per al funcionament de la xarxa, disposant per a això d'un
      equip tècnic i informàtic adequat, i a gestionar les operacions de
      muntatge de la compra realitzada a través dels seus tallers.
    </p>
    <p>
      El CLIENT es compromet a utilitzar els serveis que es posen a la seva
      disposició de bona fe, sense vulnerar les lleis vigents en qualsevol
      matèria o infringir drets de terceres persones, així com al pagament del
      producte seleccionat en el temps i forma fixats en les presents condicions
      de venda i a la recepció en el lloc indicat per al lliurament.
    </p>
    <p>
      RODI no garanteix que la disponibilitat del servei objecte d'aquest
      contracte sigui contínua i ininterrompuda, per circumstàncies originades
      per problemes a la xarxa d'Internet, avaries en els dispositius
      informàtics i altres circumstàncies imprevisibles. De manera que el CLIENT
      accepta suportar dins d'uns límits raonables aquestes circumstàncies, per
      la qual cosa renuncia expressament a reclamar a RODI qualsevol
      responsabilitat contractual o extracontractual pels possibles errors i ús
      del servei contractat.
    </p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>11. Comunicacions</strong>
    </h2>
    <p>
      Totes les notificacions entre les parts es realitzaran preferentment a
      través de correu electrònic. El CLIENT és responsable de la confirmació de
      la recepció de les notificacions i de posar en coneixement de RODI
      qualsevol modificació sobre les seves dades, quedant aquesta exonerada de
      qualsevol responsabilitat derivada per aquesta circumstància. En cas de
      modificació el CLIENT haurà de posar-ho en coneixement de RODI en l'e-mail
      o telèfons indicats en la nostra pàgina web.
    </p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>12. Compromís, acceptació i validesa del contracte</strong>
    </h2>
    <p>
      El CLIENT reconeix haver llegit i acceptat les condicions legals d'ús i la
      política de privacitat de la pàgina web.
    </p>
    <p>
      El CLIENT reconeix que ha entès tota la informació respecte als productes
      i serveis, incloent-hi el de lliurament, oferts a la nostra pàgina web,
      així com totes les condicions i estipulacions recollides en el present
      contracte electrònic, pel que afirma que són suficients per a l'exclusió
      de l'error en el consentiment del present contracte i, per tant, les
      accepta integra i expressament.
    </p>
    <p>
      El CLIENT és plenament conscient que l'acceptació i execució del present
      contracte tindrà lloc per mitjà del subministrament de les seves dades i
      la pulsació del botó "Comprar" indicat a la nostra pàgina web i que es
      perfeccionarà amb el pagament del servei.
    </p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>13. Normativa Aplicable</strong>
    </h2>
    <p>
      El present contracte té caràcter mercantil, i es regirà i interpretarà
      d'acord amb les lleis espanyoles.
    </p>
    <p>
      En el cas que es produeixi qualsevol tipus de discrepància o diferència
      entre les parts en relació amb la interpretació, contingut o execució del
      Contracte que no sigui solucionada de mutu acord, les parts se sotmetran
      als jutjats o tribunals que resultessin competents en cada cas .
    </p>
    <p> </p>
    <h2 className={styles.section_title}>
      <strong>14. Dades personals</strong>
    </h2>
    <p>
      El procés de contractació requereix que ompli formularis de dades
      personals amb la finalitat de tramitar i gestionar les comandes i, si ho
      autoritza, per remetre-li informació comercial sobre els nostres productes
      i serveis. El subministrament d'aquestes dades personals requereix
      l'aprovació prèvia de la nostra Política de Privacitat.
    </p>
    <p> </p>
    <p>
      <strong>Atenció:</strong> Les presents Condicions Generals de Compra han
      estat actualitzades amb data <strong>30/07/2019. </strong>En qualsevol
      moment podem procedir a la seva modificació, així com variar la relació
      dels preus i productes oferts. Si us plau, comprovi la data d'emissió en
      cada ocasió en que es connecti a la nostra pàgina Web i així tindrà la
      certesa que no s'ha produït modificació alguna que li afecti.
    </p>
  </div>
)

const CondicionesEs = ({ styles }: Props) => (
  <div className={styles.container}>
    <h1 className={styles.title}>Condiciones generales de contratación</h1>
    <p>
      El presente acuerdo regula las condiciones generales de compra de los
      diversos productos y servicios ofertados en la presente página web por
      RODI METRO, S.L. (en adelante RODI) con NIF nº B60864311 y domicilio en C/
      Pere de Cabrera, 16, 5ª planta de (25001) Lleida (ESPAÑA).
    </p>
    <p>
      El CLIENTE en el momento que completa el proceso de registro, y compra
      alguno de nuestros productos, acepta y se somete expresamente a las
      cláusulas especificadas a continuación, así como a las condiciones de
      acceso y uso de nuestro portal y su política de privacidad, accesibles a
      través del enlace “Avisos Legales”.
    </p>
    <p>
      El CLIENTE reconoce disponer de capacidad legal suficiente para obligarse
      contractualmente.
    </p>
    <p>
      El presente contrato está permanentemente accesible en los avisos legales
      de nuestra página web, y se puede descargar en el momento de la
      contratación.
    </p>

    <h2 className={styles.section_title}>
      <strong>1. Precios e impuestos</strong>
    </h2>

    <p>
      En contraprestación por el producto adquirido, el CLIENTE acepta pagar
      expresamente a RODI las cantidades especificadas en el momento de la
      compra en la relación de precios establecidas en la web{' '}
      <u>
        <a href="https://www.rodi.es/">www.rodi.es</a>
      </u>{' '}
      para los productos elegidos.
    </p>
    <p>
      Los productos que se ofrecen en esta web están sujetos al Impuesto del
      Valor Añadido (IVA) que ESTÁ INCLUIDO en los precios que se detallan para
      cada producto.
    </p>
    <p>
      El coste de montaje o instalación de los productos NO ESTÁ INCLUIDO en el
      precio.
    </p>
    <p>
      Una vez finalizada la selección de productos y de manera previa a la
      confirmación del pedido y antes de proceder al pago del mismo, en pantalla
      aparecerá el precio final de la compra que incluirá el IVA (Impuesto sobre
      el Valor Añadido), los gastos de instalación y de montaje, que se
      reflejarán en la orden de pedido en el momento de efectuar la compra y en
      la factura.
    </p>
    <p>
      No se incluye todo aquello que no aparezca especificado en la descripción
      como incluido.
    </p>

    <h2 className={styles.section_title}>
      <strong>2. Procedimiento de compra</strong>
    </h2>
    <p>
      A través de esta página web solamente podrán ser realizados pedidos por
      personas físicas mayores de 14 años y sociedades.
    </p>
    <p>
      En la página web se detallan todos nuestros productos de manera
      individualizada. El procedimiento para efectuar y gestionar la compra se
      va indicando en la página web y sólo deben seguirse los siguientes pasos:
    </p>
    <ol className={styles.listNumber}>
      <li>
        <p>Elegir el producto que desee adquirir.</p>
      </li>
      <li>
        <p>
          Al seleccionar un producto podrá ser añadido a la Cesta de la Compra y
          podrá elegir entre seguir comprando o tramitar el pedido.
        </p>
      </li>
      <li>
        <p>
          Para tramitar el pedido, si es la primera vez que accede a nuestro
          portal, es preciso cumplimentar un formulario de registro con sus
          datos personales y crear una cuenta personal como usuario. Completado
          este paso recibirá, en el correo electrónico que haya indicado, la
          confirmación de que el registro se ha realizado correctamente. En caso
          de que sea un usuario ya registrado, para continuar con el proceso de
          compra bastará que introduzca el nombre de usuario y contraseña que
          generó en la primera compra. El código de usuario y la contraseña son
          personales y corresponden a la persona que efectuó el proceso de
          registro, quien es responsable exclusivo su custodia. A través de su
          cuenta podrá gestionar la información que disponemos y modificar o
          actualizar sus datos.
        </p>
      </li>
      <li>
        <p>
          A continuación, se procederá a la verificación del pedido donde se
          detallan uno a uno los artículos seleccionados y se calcula el precio
          total, incluyendo gastos de taller e impuestos.
        </p>
      </li>
      <li>
        <p>
          Asimismo, será necesario seleccionar el taller donde desea que se le
          instale el producto y seleccionar una fecha a modo de cita para el
          montaje.
        </p>
      </li>
      <li>
        <p>
          Una vez tramitado el proceso, será necesario marcar de manera previa
          una casilla de verificación de lectura y aceptación los términos y
          condiciones de venta.
        </p>
      </li>
      <li>
        <p>
          En la página web se le facilitarán los mecanismos para que pueda
          efectuarse el pago en función del sistema elegido en cada caso.
        </p>
      </li>
      <li>
        <p>
          Una vez introducidos los datos para el pago, deberá confirmar el
          pedido pulsando el botón "Comprar".
        </p>
      </li>
      <li>
        <p>
          Una vez pulsado el botón "Comprar" recibirá instrucciones para la
          entrega del producto.
        </p>
      </li>
    </ol>
    <p>
      El CLIENTE se da por informado de que las fotografías y descripciones de
      los productos en la página web son una imagen aproximada de los productos
      y servicios ofertados por lo que es posible que puedan diferir del
      original. Las descripciones e ilustraciones de los productos ofrecidos a
      través de la presente página web se realizan meramente a título
      informativo.
    </p>

    <h2 className={styles.section_title}>
      <strong>3. Disponibilidad y Entrega</strong>
    </h2>

    <p>
      Los productos ofertados por RODI se encuentran disponibles para su compra
      en toda la Unión Europea pero la entrega y el montaje se realizará
      exclusivamente en uno de los talleres disponibles en la web.
    </p>
    <p>
      Los pedidos se procesarán una vez que se haya recibido correctamente el
      pago.
    </p>
    <p>
      Los productos ofertados por RODI estarán siempre sujetos a disponibilidad
      que se indicará específicamente en nuestra Web para cada producto por lo
      que, en caso de no encontrarse disponible algún artículo ofertado, se
      informará debidamente de la falta de disponibilidad al cliente para la
      compra. En condiciones normales, todos los productos que aparecen en
      nuestra Web se encuentran disponibles para su montaje.
    </p>
    <p>
      En los casos en los que, una vez realizado el pedido, el producto
      contratado por el CLIENTE se encuentre agotado, se le informará de ello
      debidamente y se le ofrecerá un producto alternativo de características
      similares de igual precio y calidad al comprado. Si, aun así, el CLIENTE
      no estuviera interesado en aceptar la alternativa propuesta, RODI
      procederá a la devolución de la suma ya abonada y le informará de los
      trámites y plazos de reembolso.
    </p>
    <p>
      El CLIENTE deberá seleccionar una de las fechas disponibles en el taller
      de su elección que, una vez marcada, será la fecha de entrega del
      producto.
    </p>
    <p>
      Las entregas e instalaciones se realizarán en horario de apertura
      comercial de nuestros talleres.
    </p>
    <p>
      Con el objeto de que en todo momento esté informado de la gestión de su
      compra, recibirá por correo electrónico un aviso de la confirmación de su
      pedido y del pago.
    </p>
    <p>
      Asimismo, el CLIENTE puede consultar el estado de su pedido llamando en
      horario comercial de apertura al teléfono 900 921 790 o enviando un correo
      electrónico a la dirección{' '}
      <u>
        <a href="mailto:atencionalcliente@rodi.es">atencionalcliente@rodi.es</a>
      </u>
      .
    </p>
    <h2 className={styles.section_title}>
      <strong>4. Pago y periodos de pago</strong>
    </h2>

    <p>Se acepta las siguientes formas de pago:</p>
    <ul>
      <li>
        <p>
          Tarjeta de Crédito: La transacción se realiza conectando directamente
          con la entidad bancaria a través de su pasarela de pago o,
          directamente, en el taller.
        </p>
      </li>
    </ul>
    <p>
      En los pagos a través de la web, una vez realizado el pago, recibirá un
      correo electrónico de confirmación del mismo.
    </p>
    <p>
      RODI no tiene conocimiento de los datos referentes a su tarjeta de
      crédito. Todos nuestros sistemas de pago son totalmente seguros.
    </p>
    <h2 className={styles.section_title}>
      <strong>5. Facturas</strong>
    </h2>
    <p>
      Una vez efectuado y completado el servicio en el taller, recibirá la
      Factura de la compra. Esta será en formato PDF en su espacio personal
      dentro de la web o en papel si la solicita en el taller.
      <br />
      <br /> El CLIENTE es responsable de la confirmación de la recepción de las
      notificaciones y de poner en nuestro conocimiento cualquier modificación
      sobre sus datos, quedando RODI exonerada de cualquier responsabilidad
      derivada por esta circunstancia.
    </p>

    <h2 className={styles.section_title}>
      <strong>6. Garantía</strong>
    </h2>

    <p>
      Todos nuestros productos quedan sometidos a controles de calidad y están
      garantizados contra defectos de producción por un plazo de 2 años desde la
      fecha de compra. La garantía cubre cualquier vicio de fabricación, de
      diseño o de material, aunque será necesario que se ponga de manifiesto la
      falta de conformidad en un plazo de 14 días desde que tuvo conocimiento de
      ella. La garantía cubre únicamente los productos defectuosos. No cubre un
      uso inadecuado, u otros que no sean atribuibles a un defecto del proveedor
      o a una tara en el producto. Durante el periodo de garantía el CLIENTE
      podrá acudir al taller donde le instalaron el producto y procederemos a su
      reparación o sustitución.
    </p>
    <p>El documento de garantía es la factura de compra.</p>

    <h2 className={styles.section_title}>
      <strong>7. Devoluciones</strong>
    </h2>

    <p>
      Sólo se admitirán devoluciones o cambios de los productos en caso de
      defecto de fábrica o errores de montaje. En cualquier caso, no deberán
      haber transcurrido más de 14 días desde la recepción del producto. Se
      comprobará previamente a la aceptación de que se trata de un defecto o
      anomalía de fabricación o de embalaje y no un uso, inadecuado o
      negligente. Se acompañarán siempre de la correspondiente la factura.
    </p>
    <p>
      Tanto en el caso de devolución por defecto de fabricación como en el caso
      de error en el pedido se ofrecerá a CLIENTE, si es posible, la reparación
      de la pieza o su sustitución por otra alternativa sin coste adicional y
      sin derecho, por parte del CLIENTE, a ningún tipo de compensación.
    </p>
    <p>
      En todo caso, la devolución quedará siempre sujeta a previo examen por
      parte de RODI del estado del producto objeto de devolución y a la
      verificación de que se han cumplido los requisitos exigibles en las
      presentes condiciones de venta. Una vez recibida la devolución y en caso
      de que proceda, reembolsaremos el importe cobrado en un plazo máximo de 30
      días y en los mismos términos que se usaron para realizar el pago.
    </p>
    <p>
      RODI se reserva el derecho de rechazar devoluciones comunicadas fuera del
      plazo fijado o de productos que no reúnan los criterios para su
      devolución.
    </p>
    <p>
      Las devoluciones se gestionarán a través del envío de un correo
      electrónico a{' '}
      <u>
        <a href="mailto:atencionalcliente@rodi.es">atencionalcliente@rodi.es</a>
      </u>{' '}
      o acudiendo al taller realizando una descripción de los motivos y de las
      causas alegadas para la devolución. Asimismo, será necesario señalar el
      nombre y apellidos y el número de referencia del pedido. Una vez recibida
      la petición, la tramitaremos oportunamente.
    </p>

    <h2 className={styles.section_title}>
      <strong>8. Derecho de Desistimiento</strong>
    </h2>

    <p>
      Tiene usted derecho a desistir del presente contrato en un plazo de 14
      días naturales sin necesidad de justificación.
    </p>
    <p>
      El plazo de desistimiento expirará, en cualquier caso, en el momento de la
      instalación del producto en el taller.
    </p>
    <p>
      Para ejercer el derecho de desistimiento, deberá usted notificarnos a RODI
      METRO, S.L. C/ Pere de Cabrera, 16, 5ª planta, 25001 Lleida, correo
      electrónico:{' '}
      <u>
        <a href="mailto:atencionalcliente@rodi.es">atencionalcliente@rodi.es</a>
      </u>
      , su decisión de desistir del contrato a través de una declaración
      inequívoca (por ejemplo, una carta enviada por correo postal, fax o correo
      electrónico). Podrá utilizar el modelo de formulario de desistimiento que
      figura a continuación, aunque su uso no es obligatorio.
    </p>
    <p>Modelo de formulario de desistimiento</p>
    <p>A la atención de</p>
    <p>RODI METRO, S.L.</p>
    <p>C/ Pere de Cabrera, 16, 5ª planta</p>
    <p>25001 Lleida</p>
    <p>
      <u>
        <a href="mailto:atencionalcliente@rodi.es">atencionalcliente@rodi.es</a>
      </u>
    </p>
    <p>
      Por la presente le comunico que desisto de mi contrato de venta del
      siguiente bien:
    </p>
    <p>Recibido/Pedido el:</p>
    <p>Nombre del consumidor:</p>
    <p>Firma del consumidor</p>
    <p>Fecha</p>
    <p> </p>
    <p>
      Para cumplir el plazo de desistimiento, basta con que la comunicación
      relativa al ejercicio por su parte de este derecho sea enviada antes de
      que venza el plazo correspondiente.
    </p>
    <p>
      <strong>Consecuencias del desistimiento:</strong>
    </p>
    <p>
      En caso de desistimiento por su parte, le devolveremos todos los pagos
      recibidos de usted, sin ninguna demora indebida y, en todo caso, a más
      tardar 14 días naturales a partir de la fecha en la que se nos informe de
      su decisión de desistir del presente contrato. Procederemos a efectuar
      dicho reembolso utilizando el mismo medio de pago empleado por usted para
      la transacción inicial, a no ser que haya usted dispuesto expresamente lo
      contrario; en todo caso, no incurrirá en ningún gasto como consecuencia
      del reembolso.
    </p>

    <h2 className={styles.section_title}>
      <strong>
        9. Perfección del contrato y modificaciones de los servicios
      </strong>
    </h2>

    <p>
      El presente contrato se perfeccionará con la instalación y pago del
      producto.
    </p>
    <p>
      RODI se reserva el derecho a modificar de cualquier forma las
      características y condiciones de sus servicios, siempre con el objetivo de
      mejorarlos y que suponga un beneficio para el CLIENTE.
    </p>

    <h2 className={styles.section_title}>
      <strong>10. Obligaciones de las partes</strong>
    </h2>
    <p>
      RODI se compromete a proporcionar al CLIENTE los productos y servicios que
      hayan sido contratados aplicando la máxima diligencia en la prestación de
      los mismos, así como a cuidar del mantenimiento de las instalaciones
      necesarias para el funcionamiento de la red, disponiendo para ello de un
      equipo técnico e informático adecuado, y a gestionar las operaciones de
      montaje de la compra realizada a través de sus talleres.
    </p>
    <p>
      El CLIENTE se compromete a utilizar los servicios que se ponen a su
      disposición de buena fe, sin vulnerar las leyes vigentes en cualquier
      materia o infringir derechos de terceras personas, así como al pago del
      producto seleccionado en el tiempo y forma fijado en las presentes
      condiciones de venta y a su recepción en el lugar indicado para la
      entrega.
    </p>
    <p>
      RODI no garantiza que la disponibilidad del servicio objeto de este
      contrato sea continua e ininterrumpida, por circunstancias originadas por
      problemas en la red de Internet, averías en los dispositivos informáticos
      y otras circunstancias imprevisibles. De manera que el CLIENTE acepta
      soportar dentro de unos límites razonables estas circunstancias, por lo
      que renuncia expresamente a reclamar a RODI cualquier responsabilidad
      contractual o extracontractual por los posibles fallos, errores y uso del
      servicio contratado.
    </p>
    <h2 className={styles.section_title}>
      <strong>11. Comunicaciones</strong>
    </h2>

    <p>
      Todas las notificaciones entre las partes se realizarán preferentemente a
      través de correo electrónico. El CLIENTE es responsable de la confirmación
      de la recepción de las notificaciones y de poner en conocimiento de RODI
      cualquier modificación sobre sus datos, quedando ésta exonerada de
      cualquier responsabilidad derivada por esta circunstancia. En caso de
      modificación el CLIENTE deberá ponerlo en conocimiento de RODI en el
      e-mail o teléfonos indicados en nuestra página web.
    </p>

    <h2 className={styles.section_title}>
      <strong>12. Compromiso, aceptación y validez del contrato</strong>
    </h2>
    <p>
      El CLIENTE reconoce haber leído y aceptado las condiciones legales de uso
      y la política de privacidad de la página web.
    </p>
    <p>
      El CLIENTE reconoce que ha entendido toda la información respecto a los
      productos y servicios, incluyendo, en su caso, el de entrega, ofrecidos en
      nuestra página web, así como todas las condiciones y estipulaciones
      recogidas en el presente contrato electrónico, por lo que afirma que son
      suficientes para la exclusión del error en el consentimiento del presente
      contrato y, por lo tanto, las acepta integra y expresamente.
    </p>
    <p>
      El CLIENTE es plenamente consciente de que la aceptación y ejecución del
      presente contrato tendrá lugar por medio del suministro de sus datos y la
      pulsación del botón “Comprar” indicado en nuestra página web y que se
      perfeccionará con el pago del servicio.
    </p>

    <h2 className={styles.section_title}>
      <strong>13. Normativa Aplicable</strong>
    </h2>

    <p>
      El presente contrato tiene carácter mercantil, y se regirá e interpretará
      de acuerdo con las leyes españolas.
    </p>
    <p>
      En el caso de que se produzca cualquier tipo de discrepancia o diferencia
      entre las partes en relación con la interpretación, contenido o ejecución
      del Contrato que no sea solucionada de mutuo acuerdo, las Partes se
      someterán a los juzgados o tribunales que resultaran competentes en cada
      caso.
    </p>

    <h2 className={styles.section_title}>
      <strong>14. Datos personales </strong>
    </h2>
    <p>
      El proceso de contratación requiere que cumplimente formularios de datos
      personales con la finalidad de tramitar y gestionar los pedidos y, si lo
      autoriza, para remitirle información comercial sobre nuestros productos y
      servicios. El suministro de dichos datos personales requiere la aprobación
      previa de nuestra Política de Privacidad.
    </p>
    <p> </p>
    <p>
      <strong>Atención:</strong> Las presentes Condiciones Generales de Compra
      han sido actualizadas con fecha 30/7/2019. En cualquier momento podemos
      proceder a su modificación, así como variar la relación de los precios y
      productos ofertados. Por favor, compruebe la fecha de emisión en cada
      ocasión en que se conecte a nuestra página Web y así tendrá la certeza de
      que no se ha producido modificación alguna que le afecte.
    </p>
  </div>
)

const CondicionesContents = ({ ...props }: Props) =>
  locale === 'es' ? <CondicionesEs {...props} /> : <CondicionesCa {...props} />

export default CondicionesContents
